.application {
    width: 100%;
}

.application__inner {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 6rem 8rem;
    padding-top: 8rem;
    @media screen and (max-width: 1024px) {
       padding: 6rem 1rem;
    }
}

.application__title {
    max-width: 479px;
    width: 100%;
    font-size: 2rem;
    line-height: 1.5;
    margin-bottom: 4rem;
    @media screen and (max-width: 768px) {
        margin-bottom: 1rem;
    }
}

.application__title::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000;
    margin-top: 1rem;
}

.application__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.application__file-input {
    display: none;
}

.application__file-label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 300px;
    background-color: #DFE8EB;
    padding: 1rem;
    font-family: 'Aktiv-Grotesk';
    cursor: pointer;
}

.application__file-info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
    background-color: #DFE8EB;
    border-radius: 4px;
}

.application__file-remove {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.application__submit {
    max-width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: bold;
    padding: 0.5rem 2.5rem;
    border: 2px solid #32bef0;
    border-radius: 27px;
    font-family: 'termina';
    font-size: 0.8rem;
    background-color: #fff;
    cursor: pointer;
    transition: all .3s ease;
}

.application__submit:hover {
    background-color: #32bef0;
}

.application__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.application__label_text {
    font-size: 0.8rem;
    text-align: center;
}

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: #32bef0;
    padding: 2rem;
    border: none;
    border-radius: 15px;
    max-width: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 1rem;
}

.Modal p {
    line-height: 1.5;
}

.Modal button {
    font-weight: bold;
    background-color: #fff;
    border-radius: 27px;
    padding: 1rem 2rem;
    color: #000;
    font-family: 'termina';
    font-size: 0.8rem;
    border: none;
    cursor: pointer;
    transition: all .3s ease;
}

.Modal button:hover {
    background-color: #fec525;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
}
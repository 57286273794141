.awards-page {
  width: 100%;
}

.awards-page__inner {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 6rem 8rem;
  padding-top: 8rem;
  padding-right: 0;
  @media screen and (max-width: 1024px) {
    padding: 6rem 1rem;
    padding-right: 0;
  }
}
.awards-page__information {
  text-align: center;
  font-weight: bold;
  background-color: #dfe8eb;
  padding: 1rem;
  line-height: 1.5;
  margin: 6rem 8rem;
  margin-top: 6rem;
  margin-bottom: -4rem;
  @media screen and (max-width: 768px) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

.awards-page__title {
  max-width: 174px;
  font-size: 2rem;
}

.awards-page__title::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000;
  margin-top: 1rem;
}

.awards-page__subtitle {
  line-height: 1.5;
}

.awards-page__warning {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.awards-page__warning_icon {
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  border-radius: 50%;
  border: 1px solid #fb5126;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fb5126;
}

.awards-page__warning p {
  color: #fb5126;
  line-height: 1.5;
  font-size: 0.8rem;
}

.awards-page__main_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 96px;
}

.awards-page__container_text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 4rem;
}

.awards-page__container_points {
  font-weight: bold;
}

.awards-page__container_info {
  color: red;
  font-size: 0.8rem;
}

.awards-page__container_awards_wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  overflow-y: hidden;
  overflow-x: scroll;
  margin-top: 2rem;
  padding-bottom: 2rem;
}

.awards-page__container_awards_wrapper::-webkit-scrollbar {
  height: 10px; /* Wysokość poziomego paska przewijania */
  background-color: #e0e0e0; /* Kolor tła paska przewijania */
  border-radius: 10px; /* Zaokrąglone końce */
}

.awards-page__container_awards_wrapper::-webkit-scrollbar-thumb {
  background-color: #a0a0a0; /* Kolor suwaka */
  border-radius: 10px; /* Zaokrąglone końce suwaka */
  border: 3px solid #e0e0e0; /* Obramowanie suwaka */
}

.awards-page__container_awards_wrapper::-webkit-scrollbar-track {
  background-color: #e0e0e0; /* Kolor tła toru */
  border-radius: 10px; /* Zaokrąglone końce toru */
}

.awards-page__container_link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: #000;
  text-decoration: none;
}

.awards-page__container_link img {
  width: 400px;
  min-width: 400px;
  height: 400px;
  min-height: 400px;
  object-fit: contain;
  background-color: #efefef;
  @media screen and (max-width: 768px) {
    width: 300px;
    min-width: 300px;
    height: 300px;
    min-height: 300px;
  }
}

.awards-page__container_button {
  border: none;
  border: 2px solid #32bef0;
  font-family: "termina";
  font-weight: bold;
  background-color: #fff;
  padding: 1rem 2rem;
  border-radius: 27px;
  max-width: 250px;
  width: 100%;
  color: #000;
  cursor: pointer;
}

.awards-page__points {
  color: #fff;
  background-color: #32bef0;
  padding: 0.5rem 1rem;
  border-radius: 27px;
  font-weight: 600;
}

@import url('./fonts/fonts.scss');
@import url('./variables/variables.scss');
@import url('./default/reset.scss');


// PAGES / COMPONETNTS
@import url('./pages/home.scss');
@import url('./pages/register.scss');
@import url('./pages/homelogged/homelogged.scss');
@import url('./pages/homelogged/actionrulessection.scss');
@import url('./pages/homelogged/awardssection.scss');
@import url('./pages/homelogged/extrapointssection.scss');
@import url('./pages/homelogged/tripsection.scss');
@import url('./pages/homelogged/contactsection.scss');
@import url('./pages/competition/welcomesection.scss');
@import url('./pages/competition/competitionrulessection.scss');
@import url('./pages/sale-products/main.scss');
@import url('./pages/awards/main.scss');
@import url('./pages/account/main.scss');
@import url('./pages/quizzes/main.scss');
@import url('./pages/application/main.scss');
@import url('./pages/award-details/main.scss');

// COMPONENTS
@import url('./components/header.scss');
@import url('./components/footer.scss');


/* HTML: <div class="loader"></div> */
.loader {
    width: 15px;
    height: 15px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 3px solid #000;
    animation:
      l20-1 0.8s infinite linear alternate,
      l20-2 1.6s infinite linear;
  }
  @keyframes l20-1{
     0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
     12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
     25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
     50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
     62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
     75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
     100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
  }
  @keyframes l20-2{ 
    0%    {transform:scaleY(1)  rotate(0deg)}
    49.99%{transform:scaleY(1)  rotate(135deg)}
    50%   {transform:scaleY(-1) rotate(0deg)}
    100%  {transform:scaleY(-1) rotate(-135deg)}
  }

.loader--light {
    border-color: #fff;
}

.ReactModal__Overlay {
  z-index: 99999;
}

.application__title--summary {
  max-width: 668px;
}

.summary_col {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.summary__wrapper_form_col {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.summary_wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.summary_button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  max-width: 300px;
  width: 100%;
  border: 2px solid #32bef0;
  background-color: #fff;
  color: #000;
  border-radius: 27px;
  padding: 0.75rem 2rem;
  font-weight: bold;
  font-size: 0.8rem;
  font-family: 'termina';
  cursor: pointer;
  transition: .3s ease;
  @media screen and (max-width: 768px) {
      width: 100%;
  }
}

.summary_button:hover {
  background-color: #32bef0;
}

.summary_form {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.summary_position_button {
  max-width: 400px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 2.5rem;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.summary_button_back {
  color: #32bef0;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.summary_button_back img {
  max-width: 25px;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 100000;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #32BEF0;
  border-radius: 15px;
  width: 450px;
  position: relative;
  transition: all 5s ease-in-out;
  text-align: center;
}

.popup h2 {
  margin-top: 0;
  font-size: 18px;
}

.popup .content {
  padding-top: 24px;
  font-size: 16px;
  line-height: 19px;
}

.popup .rule {
  padding-top: 24px;
  label {
    font-size: 14px;
    padding-left: 20px;
    display: block;

    a {
      font-weight: bold;
      color: #000;
    }

    input {
      margin-left: -30px;
    }
  }
}

.popup {

  .rulesErrorNotification {
    font-size: 14px;
    color: #f00;
    font-weight: bold;
    text-align: center;
  }

  button {
    display: block;
    margin: 15px auto;
    border: 0;
    border-radius: 27px;
    padding: 10px 25px;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    font-weight: bold;

    &.buttonYellow {
      background: #FEC526;
    }

    &.buttonWhite {
      background: #FFFFFF;
    }
  }
}

@media screen and (max-width: 700px){
  .box{
    width: 70%;
  }
  .popup{
    width: 70%;
  }
}
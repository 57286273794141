.contact {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.contact__inner {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 6rem 8rem;
    @media screen and (max-width: 1024px) {
       padding: 6rem 1rem;
    }
}

.contact__title {
    width: 100%;
    font-size: 2rem;
}

.contact__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem;
    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr;
    }
}

.contact__input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #000;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    outline: none;
    transition: .3s ease linear;
    border-radius: 0;
    resize: none;
    font-family: 'aktiv-grotesk';
}

.contact__input:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.contact__input::placeholder {
    color: #000;
}

.contact__error {
    font-size: 0.65rem;
    font-family: 'termina';
    color: red;
    margin-top: 0.4rem;
    line-height: 1.3;
    grid-column: 1 / 2 span;
}

.contact__form_text {
    font-size: .7rem;
    line-height: 1.5;
    margin: 0.5rem 0;
    font-family: 'aktiv-grotesk';
}

.contact__input--textarea {
    min-height: 150px;
    border: 1px solid #000;
    margin-top: 1rem;
    margin-bottom: 0;
}

.contact__submit_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.contact__button {
    background-color: #fff;
    border-radius: 27px;
    border: none;
    border: 2px solid #32bef0;
    color: #000;
    padding: .5rem 1rem;
    font-weight: bold;
    font-size: 0.8rem;
    cursor: pointer;
    font-family: 'termina';
    width: 250px;
    margin-top: 1.5rem;
    transition: all .3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
        font-size: 0.8rem;
        padding: 1rem 1rem;
        width: 100%;
    }
}

.contact__button:hover {
    background-color: #32bef0;
}

.contact__success {
    width: 100%;
    margin-top: 1rem;
    font-size: 0.8rem;
    color: green;
}
.sale-products {
    width: 100%;
}

.sale-products__inner {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 6rem 8rem;
    padding-top: 8rem;
    padding-right: 0;
    @media screen and (max-width: 1024px) {
       padding: 6rem 1rem;
    }
}

.sale-products__inner h1 {
    margin-bottom: 4rem;
    max-width: 518px;
    width: 100%;
    line-height: 1.5;
    @media screen and (max-width: 768px) {
        margin-bottom: 2rem;
    }
}

.sale-products__inner h1::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000;
    margin-top: 1rem;
}

.sale-products__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    overflow-y: hidden;
    overflow-x: scroll;
    padding-bottom: 2rem;
    cursor: normal;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        overflow-y: hidden;
        gap: 4rem;
        padding-bottom: 0;
    }
}

.sale-products__wrapper::-webkit-scrollbar {
    height: 10px; /* Wysokość poziomego paska przewijania */
    background-color: #e0e0e0; /* Kolor tła paska przewijania */
    border-radius: 10px; /* Zaokrąglone końce */
    @media screen and (max-width: 768px) {
        height: 0;
    }
}

.sale-products__wrapper::-webkit-scrollbar-thumb {
    background-color: #a0a0a0; /* Kolor suwaka */
    border-radius: 10px; /* Zaokrąglone końce suwaka */
    border: 3px solid #e0e0e0; /* Obramowanie suwaka */
}

.sale-products__wrapper::-webkit-scrollbar-track {
    background-color: #e0e0e0; /* Kolor tła toru */
    border-radius: 10px; /* Zaokrąglone końce toru */
}

.sale-products__link {
    display: block;
    color: #000;
    text-decoration: none;
    cursor: auto;
}

.sale-products__link img {
    width: 400px;
    min-width: 400px;
    height: 400px;
    min-height: 400px;
    object-fit: contain;
    background-color: #efefef;
    @media screen and (max-width: 768px) {
        width: 100%;
        min-width: 0;
    }
}

.sale-products__link p {
    margin-top: 2rem;
    font-weight: bold;
    line-height: 1.3;
}

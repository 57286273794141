.quizzes {
    width: 100%;
}

.quizzes__inner {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 6rem 8rem;
    padding-top: 8rem;
    padding-right: 0;
    @media screen and (max-width: 1024px) {
       padding: 6rem 1rem;
    }
}

.quizzes__inner-single {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 6rem 8rem;
    padding-top: 8rem;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1024px) {
       padding: 6rem 1rem;
    }
}

.quizzes__title {
    max-width: 114px;
    width: 100%;
    font-size: 2rem;
    line-height: 1.5;
}

.quizzes__title::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000;
    margin-top: 1rem;
}

.quizzes__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    overflow-y: hidden;
    overflow-x: scroll;
    padding-bottom: 2rem;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        overflow-y: hidden;
        gap: 4rem;
        padding-bottom: 0;
    }
}

.quizzes__wrapper::-webkit-scrollbar {
    height: 10px; /* Wysokość poziomego paska przewijania */
    background-color: #e0e0e0; /* Kolor tła paska przewijania */
    border-radius: 10px; /* Zaokrąglone końce */
    @media screen and (max-width: 768px) {
        height: 0;
    }
}

.quizzes__wrapper::-webkit-scrollbar-thumb {
    background-color: #a0a0a0; /* Kolor suwaka */
    border-radius: 10px; /* Zaokrąglone końce suwaka */
    border: 3px solid #e0e0e0; /* Obramowanie suwaka */
}

.quizzes__wrapper::-webkit-scrollbar-track {
    background-color: #e0e0e0; /* Kolor tła toru */
    border-radius: 10px; /* Zaokrąglone końce toru */
}

.quizzes__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.quizzes__square {
    position: relative;
    background-color: #EFEFEF;
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    padding-top: 3rem;
}

.quizzes__container_win_text {
    color: #000;
    font-weight: bold;
}

.quizzes__container_name {
    margin-bottom: 2rem;
}

.quizzes__container_quiz_title {
    margin-bottom: 2rem;
    font-weight: 600;
    color: #FB5126;
    font-size: 1.4rem;
}

.quizzes__container_quiz_title--active {
    color: #32BEF0;
}

.quizzes__flower_img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 112px;
}

.quizzes__reward_img {
    width: 170px;
}

.quizzes__reward_img--aeno {
    width: 208px;
}

.quizzes__availability {
    color: #32BEF0;
    font-weight: 500;
    font-family: "aktiv-grotesk";
}

.quizzes__inaccessible {
    font-weight: 500;
    font-family: "aktiv-grotesk";
    color: #FB5126;
}

.quizzes__button {
    background-color: #fff;
    border: 2px solid #32BEF0;
    padding: .75rem 3rem;
    font-weight: bold;
    border-radius: 27px;
    font-family: "termina";
    cursor: pointer;
    transition: all .3s ease;
    color: #000;
}

.quizzes__button:disabled {
    color: #999999;
    background-color: #fff;
}

.quizzes__button:hover {
    background-color: #32BEF0;
}

.quizzes__button:disabled:hover {
    background-color: #fff;
    cursor: not-allowed;
}

.quizzes__container_points_award {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    font-size: 4rem;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 0;
}

.quizzes__container_plus {
    font-size: 1.5rem;
}

.quizzes__container_extra_award {
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.3;
}

.quizzes__subtitle {
    line-height: 1.5;
    max-width: 730px;
    width: 100%;
}

.quizzes__inner-single form {
    position: relative;
    background-color: #EFEFEF;
    padding: 6rem 12rem;
    width: 100%;
    max-width: 100%;
    @media screen and (max-width: 1024px) {
       padding: 6rem;
    }
    @media screen and (max-width: 768px) {
        padding: 6rem 1rem;
     }
}

.quiz-question {
    font-size: 1.2rem;
    padding-bottom: 2rem;
    line-height: 1.5;
    font-weight: 500;
    font-family: "aktiv-grotesk";
}

.quizzes__inner-single input[type='radio'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    border: 1px solid black;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    position: relative;
}

.quizzes__inner-single input[type='radio']:checked {
    background-color: black;
}

.quizzes__inner-single input[type='radio']:checked::after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.quiz-answer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    margin-bottom: 1rem;
}

.quiz-answer label {
    transform: translateY(1px);
    font-weight: 300;
    line-height: 1.4;
}

.quizzes__inner-single .error {
    color: #FB5126;
    margin-bottom: 1rem;
    font-size: 0.8rem;
}

.span-blue {
    color: #32BEF0;
}

.quiz-steps {
    font-weight: 500;
}

.close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    border: none;
    background: none;
    cursor: pointer;
    width: 38px;
    height: 38px;
}

.close-btn img {
    width: 100%;
}

.btn-quiz {
    border: 2px solid #32BEF0;
    border-radius: 27px;
    padding: 0.1rem 2rem;
    cursor: pointer;
    transition: all .3s ease;
}

.btn-quiz:hover {
    background-color: #32bdf033;
}

.arrow-quiz {
    width: 30px;
}

.btn-quiz-back:disabled {
    opacity: .4;
    cursor: not-allowed;
}

.btn-quiz-back:hover:disabled {
    opacity: .4;
    cursor: not-allowed;
    background-color: transparent;
}

.arrow-quiz-left {
    transform: rotate(-180deg);
}

.btn-quiz-back {
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    @media screen and (max-width: 768px) {
        left: 1rem;
    }
}

.btn-quiz-next {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    @media screen and (max-width: 768px) {
        right: 1rem;
    }
}

.quizzes__inner-single .quiz-wrapper {
    position: relative;
    background-color: #EFEFEF;
    padding: 6rem 12rem;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    @media screen and (max-width: 1024px) {
       padding: 6rem;
    }
    @media screen and (max-width: 768px) {
        padding: 6rem 1rem;
     }
}

.quiz-wrapper p {
    font-weight: 600;
    text-align: center;
    line-height: 1.5;
}

.quiz-red {
    color: #FB5126;
    font-weight: 300 !important;
    line-height: 1.5;
}

.btn-quiz-retry {
    background-color: #32BEF0;
    border: none;
    padding: 0.8rem 2rem;
    font-weight: 600;
    border-radius: 27px;
    font-size: .8rem;
    font-family: 'termina';
    cursor: pointer;
}

.quiz-text-space {
    margin: 1rem 0;
}

.btn-quiz-pdf {
    padding: 1rem;
    font-family: 'termina';
    font-size: 12px;
}

.info-text {
    font-size: 0.8rem;
    padding: 0.75rem;
    line-height: 1.5;
    background-color: #32bdf05a;
    width: 100%;
}

.quizzes__container_extra_award-text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}
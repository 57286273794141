.home {
    background-image: url('../../images/baner_main_baby yetico.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100svh;
    min-height: 700px;
}

.home__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 2rem;
    z-index: 1;
    @media screen and (max-width: 768px) {
        width: 100px;
        margin-top: 2rem;
        margin-left: 1rem;
    }
}

.home__header--logo {
    display: block;
    width: 130px;
}

.home__header--logo img {
    width: 100%;
}

.home__header--logo {
    @media screen and (max-width: 768px) {
        width: 100px;
    }
}

.home__leftpanel {
    position: absolute;
    top: 0;
    left: 0;
    width: 645px;
    min-width: 550px;
    height: 100svh;
    min-height: 700px;
    background-color: white;
    // border-top-right-radius: 60%;
    // border-bottom-right-radius: 45%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    display: flex;
    align-items: center;
    padding-left: 8rem;
    @media screen and (max-width: 768px) {
        width: 100%;
        min-width: 100%;
        border-radius: 0;
        padding-left: 0;
    }
}

.home__panel, .home__wrapper_bottom {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    max-width: 320px;
    height: 100%;
    @media screen and (max-width: 768px) {
        max-width: 100%;
        justify-content: flex-start;
        gap: 0;
    }
}

.home__wrapper_bottom {
    @media screen and (max-width: 768px) {
        padding: 1.5rem;
        gap: 1rem;
    }
}

.home__text {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.home__title {
    font-size: 2.8rem;
    color: #32bef0;
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.home__subtitle--bold {
    font-size: 1.1rem;
    font-weight: bold;
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.home__subtitle {
    font-size: .75rem;
    text-transform: uppercase;
    padding-top: 0.75rem;
    @media screen and (max-width: 768px) {
        font-weight: bold;
        font-size: .9rem;
        line-height: 1.1;
    }
}

.remindpassword__subtitle {
    font-size: .75rem;
    text-transform: uppercase;
    padding-top: 0.75rem;
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.home__subtitle--mobile {
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
        font-size: 0.9rem;
        font-weight: bold;
        color: #32bef0;
        text-transform: uppercase;
        line-height: 1.1;
    }
}

.home__welcome {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}

.home__welcome_title {
    font-size: 1.1rem;
    text-transform: uppercase;
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.remindpassword__welcome_title {
    font-size: 1.1rem;
    text-transform: uppercase;
}

.emailsended__welcome_title {
    font-size: 1.1rem;
    text-transform: uppercase;
}

.home__welcome_text {
    font-size: .8rem;
    line-height: 1.4;
}

.home__form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.setnewpassword__form {
    display: flex;
    flex-direction: column;
    gap: 3.5rem;
}

.inputs-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.remindpassword__form {
    display: flex;
    flex-direction: column;
    gap: 3.5rem;
}

.home__input_wrapper {
    position: relative;
}

.home__input {
    padding-bottom: 0.2rem;
    border: none;
    border-bottom: 1px solid #000;
    font-size: .8rem;
    font-family: 'aktiv-grotesk';
    outline: none;
    width: 100%;
    border-radius: 0;
}

.home__input--password {
    padding-right: 2rem;
}

.home__toggle_password {
    position: absolute;
    right: 0.5rem;
    background: none;
    border: none;
    cursor: pointer;
}

.home__error {
    font-size: 0.65rem;
    font-family: 'termina';
    color: red;
    margin-top: 0.4rem;
    line-height: 1.5;
}

.home_form_wrapper {
    display: flex;
    justify-content: space-between;
}

.home__checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.home__checkbox label {
    font-size: 0.75rem;
    font-family: 'aktiv-grotesk';
}

.home__checkbox input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid black;
    cursor: pointer;
    position: relative;
}

.home__checkbox input[type="checkbox"]:checked::before {
    content: '✔';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: black;
}

.home__checkbox label {
    margin-left: 0;
    cursor: pointer;
}

.home__link a {
    font-size: .75rem;
    font-family: 'aktiv-grotesk';
    color: #000;
}

.home__form_button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.home__button--login {
    width: 100%;
    border: none;
    padding: 1rem;
    background-color: #fec525;
    border-radius: 25px;
    cursor: pointer;
    font-size: 0.8rem;
    font-family: 'termina';
    font-weight: bold;
    transition: .3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home__button--login:hover {
    background-color: #32bef0;
}

.home__button--register {
    width: 100%;
    border: none;
    padding: 1rem;
    background-color: white;
    border-radius: 25px;
    border: 2px solid #32bef0;
    cursor: pointer;
    font-size: 0.8rem;
    font-family: 'termina';
    font-weight: bold;
    transition: .3s ease;
    color: #000;
}

.home__button--register:hover {
    background-color: #32bef0;
}

.home__background--mobile {
    width: 100%;
    @media screen and (min-width: 769px) {
        display: none;
    }
}

.home__buttons_text {
    @media screen and (max-width: 768px) {
        font-size: 0.8rem;
    }
}

.remindpassword__wrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.emailsended__wrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    @media screen and (max-width: 768px) {
        align-items: center;
        text-align: center;
    }
}

.emailsended__correct {
    width: 50px;
}

.backToLogin {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.8rem;
    font-family: 'termina';
    color: #32bef0;
    cursor: pointer;
    text-decoration: none;
}

.backToLogin img {
    width: 25px;
}

.home-br-hide {
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.home-br-show {
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
    }
}

.timer_title {
    text-align: center;
    font-size: 2rem;
    line-height: 1.5;
}

.timer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
}

.timer__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.timer__item span {
    display: flex;
    justify-content: center;
}

.timer_number {
    font-size: 3rem;
    font-weight: bold;
    @media screen and (max-width: 768px) {
        font-size: 1.5rem;
    }
}

.timer_colon {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: 700;
    @media screen and (max-width: 768px) {
        font-size: 1.5rem;
    }
}

.timer_text {
    @media screen and (max-width: 768px) {
        font-size: 0.6rem;
    }

}

.timer_text_hidden {
    color: #fff;
}

.timer_wrapper {
    @media screen and (max-width: 768px) {
        gap: 2rem;
    }
}
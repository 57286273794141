.account {
  width: 100%;
}

.account__inner {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 6rem 8rem;
  padding-top: 8rem;
  @media screen and (max-width: 1024px) {
    padding: 6rem 1rem;
  }
}

.account__title {
  max-width: 169px;
  width: 100%;
  line-height: 1.5;
  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }
}

.account__title::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000;
  margin-top: 1rem;
}

.account__user_info {
  background-color: #fec525;
  padding: 1.5rem 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media screen and (max-width: 1100px) {
    padding: 1.5rem 1rem;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

.account__code {
  line-height: 1.5;
}

.account__user_name {
  font-weight: bold;
}

.account__content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8rem;
  @media screen and (max-width: 1100px) {
    gap: 4rem;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.account__content_data {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.account__content_title {
  font-weight: bold;
}

.account__content_wrapper_data {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.account__content_wrapper_data p {
  font-family: "aktiv-grotesk";
  font-size: 0.9rem;
}

.account__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.account__input_wrapper {
  position: relative;
  width: 100%;
}

.account__toggle_password {
  position: absolute;
  top: 11px;
  right: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.account__label {
  position: absolute;
  bottom: -7px;
  left: 0.6rem;
  background-color: #fff;
  z-index: 1;
  padding: 0.3rem;
  padding-top: 0rem;
  padding-bottom: 0.2rem;
  font-size: 0.65rem;
  font-weight: 300;
  font-family: "aktiv-grotesk";
}

.account__input {
  position: relative;
  padding: 0.5rem;
  border: none;
  border-bottom: 1px solid #000;
  font-size: 0.8rem;
  font-family: "aktiv-grotesk";
  outline: none;
  width: 100%;
  border-radius: 0;
  background-color: #fff;
  color: #000 !important;
}

.account__input:disabled {
  color: #999999 !important;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .account__input {
      position: relative;
      padding: 0.5rem;
      border: none;
      border-bottom: 1px solid #000;
      font-size: 0.8rem;
      font-family: "aktiv-grotesk";
      outline: none;
      width: 100%;
      border-radius: 0;
      background-color: #fff;
      color: #000 !important;
    }
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .account__input:disabled {
      color: #999999 !important;
    }
  }
}

.account__error {
  font-size: 0.65rem;
  font-family: "termina";
  color: red;
  margin-top: 0.4rem;
}

.account__error--login {
  position: absolute;
  left: 0;
  bottom: -20px;
}

.account__buttons_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.account__form_change_data_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: #fff;
  color: #000;
  border: none;
  font-family: "termina";
  font-size: 0.8rem;
  cursor: pointer;
}

.account__form_change_data_btn img {
  max-width: 20px;
}

.account__form_submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  border: 2px solid #32bef0;
  border-radius: 27px;
  background-color: #fff;
  padding: 0.5rem 2rem;
  font-weight: bold;
  font-size: 0.8rem;
  font-family: "termina";
  transition: 0.3s ease;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.account__form_submit_pit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  border: 2px solid #32bef0;
  border-radius: 27px;
  background-color: #fff;
  padding: 0.5rem 2rem;
  font-weight: bold;
  font-size: 0.8rem;
  font-family: "termina";
  transition: 0.3s ease;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.account__form_submit:hover {
  background-color: #32bef0;
}

.account__form_submit:disabled:hover {
  background-color: #fff;
  cursor: not-allowed;
}

.account__success {
  color: green;
  font-family: "termina";
  font-size: 0.8rem;
}

.account__input--password {
  padding-right: 2rem;
}

.historyOrders {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.historyOrders__order {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.historyOrders__order img {
  width: 100%;
  height: 100%;
  background-color: #efefef;
  object-fit: contain;
}

.historyOrders__desc {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0;
}

.historyOrders__name {
  font-weight: bold;
  font-size: 0.9rem;
  font-family: "aktiv-grotesk";
}

.historyOrders__quantity {
  font-size: 0.9rem;
  font-family: "aktiv-grotesk";
  margin-bottom: 2rem;
}

.historyOrders__name_data {
  font-weight: bold;
  font-size: 0.9rem;
  font-family: "aktiv-grotesk";
}

.historyOrders__data {
  font-size: 0.9rem;
  font-family: "aktiv-grotesk";
}

.button-order-disabled {
  cursor: not-allowed !important;
  color: rgba(16, 16, 16, 0.3) !important;
}

.button-order-disabled:hover {
  cursor: not-allowed !important;
  background-color: #fff !important;
  color: rgba(16, 16, 16, 0.3) !important;
}

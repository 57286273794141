.register {
  width: 100%;
}

.register__inner {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.register__logo {
  display: block;
  width: 130px;
}

.register__title {
  margin-top: 2rem;
  font-size: 2rem;
}

.register__disable_info {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #fb5126;
}

.register__info {
  color: gray;
  font-size: 0.8rem;
  line-height: 1.5;
  font-family: "aktiv-grotesk";
}

.register__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.register__subtitle {
  font-size: 1rem;
  line-height: 1.5;
}

.register__radio_wrapper {
  display: flex;
  flex-direction: column;
}

.register__radio {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.register__radio input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid black;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
}

.register__radio input[type="radio"]:checked {
  background-color: black;
}

.register__radio input[type="radio"]:checked::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.register__radio label {
  margin-left: 10px;
  cursor: pointer;
}

.register__radio_wrapper {
  display: flex;
  flex-direction: column;
}

.register__radio_wrapper--2 {
  display: grid;
  grid-template-columns: 20px 1fr;
}

.register__radio_wrapper--2 p {
  font-size: 0.8rem;
  margin-bottom: 1rem;
  font-family: "aktiv-grotesk";
}

.register_radios {
  margin-top: 20px;
}

.register__radio {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.register__radio input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px; /* Zmniejszono rozmiar radia */
  height: 16px; /* Zmniejszono rozmiar radia */
  border: 2px solid black;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
}

.register__radio input[type="radio"]:checked {
  background-color: black;
}

.register__radio input[type="radio"]:checked::after {
  content: "";
  display: block;
  width: 4px; /* Zmniejszono rozmiar kropeczki */
  height: 4px; /* Zmniejszono rozmiar kropeczki */
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.register__radio label {
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px; /* Zmniejszono rozmiar tekstu */
  font-family: "aktiv-grotesk";
}

.register__form_main {
  display: grid;
  grid-template-columns: 2fr 2fr 1.5fr;
  gap: 5rem;
  margin-top: 2rem;
  @media screen and (max-width: 1024px) {
    gap: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.register__form_main_epit {
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 5rem;
  margin-top: 2rem;
  @media screen and (max-width: 1024px) {
    gap: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.register__form_column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.register__column_title {
  font-size: 1rem;
  line-height: 1.5;
}

.register__input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #000;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  outline: none;
  transition: 0.3s ease linear;
  border-radius: 0;
  font-family: "aktiv-grotesk";
}

.register__input:focus {
  background-color: rgba(0, 0, 0, 0.05);
}

.register__checkbox_wrapper {
  font-size: 0.8rem;
  display: grid;
  grid-template-columns: 30px 1fr;
}

.register__checkbox_wrapper input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
}

.register__checkbox_wrapper input[type="checkbox"]:checked::before {
  content: "✔";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: black;
}

.register__button_more {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  grid-column: 1 / 2 span;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
}

.register__double_arrow {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}

.hide-more-content {
  display: none;
}

.register__checkbox_wrapper label {
  line-height: 1.5;
  font-family: "aktiv-grotesk";
}

.register__submit {
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 0.9rem;
  font-family: "termina";
  font-weight: bold;
  outline: none;
  background-color: #fff;
  border: 2px solid #32bef0;
  border-radius: 25px;
  transition: 0.3s ease;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register__submit_disabled {
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 0.9rem;
  font-family: "termina";
  font-weight: bold;
  outline: none;
  background-color: gray;
  border: 2px solid gray;
  border-radius: 25px;
  transition: 0.3s ease;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register__submit:hover {
  background-color: #32bef0;
}

.register__link {
  font-weight: 600;
  color: #000;
}

.register__error {
  font-size: 0.65rem;
  font-family: "termina";
  color: red;
  margin-top: 0.4rem;
  line-height: 1.3;
  grid-column: 1 / 2 span;
}
.register__error_api {
  font-size: 0.8rem;
  font-weight: 600;
  font-family: "termina";
  color: red;
  margin-top: 0.4rem;
  line-height: 1.3;
  grid-column: 1 / 2 span;
}

.register_pit_info {
  font-size: 1rem;
  font-family: "termina";
  color: black;
  margin-top: 0.4rem;
  margin-bottom: 0.2rem;
  line-height: 1.6;
  grid-column: 1 / 2 span;
}

.register__input_wrapper {
  position: relative;
  width: 100%;
}

.register__toggle_password {
  position: absolute;
  top: 11px;
  right: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.password-requirements div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.65rem;
  margin-top: 0.5rem;
}

.register__warning {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  background-color: #fb5126;
  padding: 1rem;
  border-radius: 12px;
}

.register__warning_icon {
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: #fb5126;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.register__warning p {
  color: #fff;
  line-height: 1.5;
  font-size: 0.8rem;
  font-family: "Aktiv-grotesk";
}

.register__warning--dekstop {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.register__warning--mobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.register__input--password {
  padding-right: 2rem;
}

.award-details {
    width: 100%;
}

.award-details__inner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 6rem 8rem;
    @media screen and (max-width: 1024px) {
       padding: 6rem 1rem;
    }
}

.award-details__subtitle {
    font-family: 'Aktiv-grotesk';
    margin-top: 1rem;
    @media screen and (max-width: 768px) {
       margin-bottom: 1rem;
    }
}

.award-details__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    @media screen and (max-width: 1300px) {
        gap: 4rem;
    }
    @media screen and (max-width: 768px) {
        gap: 2rem;
        grid-template-columns: 1fr;
    }
}

.award-details__column2 {
    display: flex;
    flex-direction: column;
    gap: 2rem;

}

.award-details__column2_title {
    font-weight: bold;
}

.award-details__column2_wrapper {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}

.award-details__column2_count {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border: 2px solid #32BEF0;
    border-radius: 8px;
}

.select-size-input {
    border: 2px solid #32BEF0;
    border-radius: 8px;
    font-weight: bold;
    width: 180px;
    text-align: center;
    background: #fff;
    padding: 5px;
}

.size-table {
    table {
        border-collapse: collapse;
    }
    table td, table th {
        border: 1px solid black;
    }
    table tr:first-child th {
        border-top: 0;
    }
    table tr:last-child td {
        border-bottom: 0;
    }
    table tr td:first-child,
    table tr th:first-child {
        border-left: 0;
    }
    table tr td:last-child,
    table tr th:last-child {
        border-right: 0;
    }
    table {
        border: 1px solid #000;
        font-size: 12px;

        td, th {
            padding: 6px 4px;
            text-align: center;
        }
    }

    @media only screen and (max-width: 760px) {
        table {
            width: 60%;
            margin: auto;
        }
        thead th {
            display: none;
        }

        td, th {
            display: block;
        }

        td[data-th]:before  {
            content: attr(data-th);
            font-weight: bold;
        }
    }
}

.award-details__subtract,
.award-details__add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    background-color: #fff;
    color: #32BEF0;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    font-size: 1.2rem;
}

.award-details__counter {
    font-weight: bold;
    font-size: 0.8rem;
    font-family: 'termina';
}

.award-details__order {
    background-color: #fff;
    border-radius: 27px;
    padding: 0.5rem 2rem;
    color: #000;
    border: 2px solid #32BEF0;
    font-weight: bold;
    cursor: pointer;
    transition: all .3s ease;
    font-family: 'termina';
}

.award-details__order:hover {
    background-color: #32BEF0;
    color: #000;
}

.award-details__column2_description_button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-family: 'termina';
    font-weight: bold;
    font-size: .9rem;

}

.award-details__column2_description {
    line-height: 1.5;
    padding-right: 0.5rem;
}

.triangle {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid black;
}

.award-details__column2_description {
    max-width: 350px;
    height: 250px;
    overflow-y: scroll;
    width: 100%;
    font-family: 'Aktiv-grotesk';
    @media screen and (max-width: 768px) {
        max-width: 100%;
    }
}

.award-details__column2_price {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Aktiv-grotesk';
}

.awards-page__warning--award {
    max-width: 350px;
    width: 100%;
    line-height: 1.3;
    font-weight: 300;
}

.awards-page__warning_icon--award {
    border-width: 0.5px;
}

.award-disabled {
    background-color: #F2F2F2;
    color: #BDBDBD;
    cursor: not-allowed;
}

.award-disabled:hover {
    background-color: #F2F2F2;
    color: #BDBDBD;
}

.award-details__column2_description::-webkit-scrollbar {
    height: 10px; /* Wysokość poziomego paska przewijania */
    background-color: #e0e0e0; /* Kolor tła paska przewijania */
    border-radius: 10px; /* Zaokrąglone końce */
    width: 8px;
}

.award-details__column2_description::-webkit-scrollbar-thumb {
    background-color: #a0a0a0; /* Kolor suwaka */
    border-radius: 10px; /* Zaokrąglone końce suwaka */
    // border: 3px solid #e0e0e0; /* Obramowanie suwaka */
}

.award-details__column2_description::-webkit-scrollbar-track {
    background-color: #e0e0e0; /* Kolor tła toru */
    border-radius: 10px; /* Zaokrąglone końce toru */
}

.award-details__image {
    width: 100%;
    background-color: #efefef;
}